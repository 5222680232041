var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('mf-button',{attrs:{"label":"Criar tema"},on:{"click":function($event){return _vm.handleOpenCreateThemeModal()}}})],1)],1),_c('mf-card-container',{attrs:{"title":"Cores"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('v-data-table',{attrs:{"loading":_vm.$apollo.loading,"search":_vm.search,"headers":_vm.headers,"items":_vm.themes,"footer-props":{ itemsPerPageOptions: [5, 10, 25, 50] }},scopedSlots:_vm._u([{key:"item.colors",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"colors-group"},_vm._l((item.colors),function(color,index){return _c('v-card',{key:index,staticClass:"color-block",style:({ backgroundColor: color })})}),1)]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',[_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":"","color":item.active ? 'green' : 'red'}},[_vm._v("mdi-brightness-1")]),_vm._v(_vm._s(item.active ? 'Disponível' : 'Indisponível')+" ")],1)],1)]}},{key:"item.default",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',[_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":"","color":item.default ? 'green' : 'red'}},[_vm._v("mdi-brightness-1")]),_vm._v(_vm._s(item.default ? 'Sim' : 'Não')+" ")],1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("more_vert")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.handleOpenUpdateThemeModal(item)}}},[_c('v-list-item-title',[_vm._v("Editar")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.removeSchemeColors(item)}}},[_c('v-list-item-title',[_vm._v("Excluir")])],1),_vm._l((_vm.app.consumer_app.urls),function(url,index){return _c('v-list',{key:index},[_c('v-list-item',{attrs:{"href":("https://" + url + "?previewSchemeId=" + (item._id)),"target":"_blank"}},[_c('v-list-item-title',[_vm._v("Ver preview em: "+_vm._s(url))])],1)],1)})],2)],1)]}}],null,true)})],1)],1)],1),(_vm.isCreateOrUpdateThemeModalOpen)?_c('create-or-update-theme-modal',{attrs:{"is-create":_vm.isCreate,"theme":_vm.themeToUpdate},on:{"close":_vm.closeModal},model:{value:(_vm.isCreateOrUpdateThemeModalOpen),callback:function ($$v) {_vm.isCreateOrUpdateThemeModalOpen=$$v},expression:"isCreateOrUpdateThemeModalOpen"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }