<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12">
        <v-row justify="end">
          <v-col cols="auto">
            <mf-button label="Criar tema" @click="handleOpenCreateThemeModal()"></mf-button>
          </v-col>
        </v-row>
        <mf-card-container v-model="search" title="Cores">
          <v-data-table :loading="$apollo.loading" :search="search" :headers="headers" :items="themes" :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }">
            <template v-slot:[`item.colors`]="{ item }">
              <div class="colors-group">
                <v-card v-for="(color, index) in item.colors" :key="index" class="color-block" :style="{ backgroundColor: color }"></v-card>
              </div>
            </template>
            <template v-slot:[`item.active`]="{ item }">
              <v-flex>
                <v-layout>
                  <v-icon x-small class="mr-1" :color="item.active ? 'green' : 'red'">mdi-brightness-1</v-icon>{{ item.active ? 'Disponível' : 'Indisponível' }}
                </v-layout>
              </v-flex>
            </template>
            <template v-slot:[`item.default`]="{ item }">
              <v-flex>
                <v-layout>
                  <v-icon x-small class="mr-1" :color="item.default ? 'green' : 'red'">mdi-brightness-1</v-icon>{{ item.default ? 'Sim' : 'Não' }}
                </v-layout>
              </v-flex>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu :close-on-content-click="false">
                <template #activator="{ on }">
                  <v-icon v-on="on">more_vert</v-icon>
                </template>
                <v-list>
                  <v-list-item @click="handleOpenUpdateThemeModal(item)">
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="removeSchemeColors(item)">
                    <v-list-item-title>Excluir</v-list-item-title>
                  </v-list-item>
                  <v-list v-for="(url, index) in app.consumer_app.urls" :key="index">
                    <v-list-item :href="`https://${url}?previewSchemeId=${item._id}`" target="_blank">
                      <v-list-item-title>Ver preview em: {{ url }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </mf-card-container>
      </v-col>
    </v-row>
    <create-or-update-theme-modal
      v-if="isCreateOrUpdateThemeModalOpen"
      v-model="isCreateOrUpdateThemeModalOpen"
      :is-create="isCreate"
      :theme="themeToUpdate"
      @close="closeModal"
    />
  </v-container>
</template>

<script>
import { GET_MERCASHOP_THEMES, MUTATION_REMOVE_MERCASHOP_THEME_COLORS } from '@/modules/accounts/graphql'
export default {
  components: {
    CreateOrUpdateThemeModal: () => import('@/modules/accounts/components/app/CreateOrUpdateThemeModal.vue')
  },
  props: {
    app: {
      type: Object,
      default: () => ({})
    },
    accountId: {
      type: String,
      default: () => ''
    },
    clientName: {
      type: String,
      default: () => ''
    }
  },
  data: () => ({
    search: '',
    isCreateOrUpdateThemeModalOpen: false,
    themeToUpdate: {},
    isCreate: true,
    headers: [
      {
        text: 'ID',
        value: '_id'
      },
      {
        text: 'Nome',
        value: 'name'
      },
      {
        text: ' ',
        value: 'colors',
        sortable: false
      },
      {
        text: 'Status',
        value: 'active'
      },
      {
        text: 'Padrão',
        value: 'default'
      },
      {
        value: 'actions'
      }
    ],
    themes: []
  }),
  apollo: {
    mercashopThemes: {
      query: GET_MERCASHOP_THEMES,
      fetchPolicy: 'no-cache',
      skip() {
        return !this.accountId
      },
      async update({ mercashopThemes }) {
        this.themes = mercashopThemes
      },
      context() {
        return {
          headers: {
            accountId: this.accountId
          },
          uri: this.$microservicesUrls['crm']
        }
      }
    }
  },
  methods: {
    closeModal(refresh) {
      if (refresh) {
        this.$apollo.queries.mercashopThemes.refresh()
      }
    },
    handleOpenCreateThemeModal() {
      this.isCreateOrUpdateThemeModalOpen = true
      this.isCreate = true
      this.themeToUpdate = {}
    },
    async handleOpenUpdateThemeModal(theme) {
      this.isCreateOrUpdateThemeModalOpen = true
      this.isCreate = false
      this.themeToUpdate = theme
    },
    async removeSchemeColors(item) {
      if (item.default) {
        return this.$alert({
          alert_message: 'Não é possível remover este tema, pois ele é padrão.',
          alert_title: 'Aviso!',
          alert_color: 'warning',
          alert_icon: 'mdi-alert-circle'
        })
      }
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_REMOVE_MERCASHOP_THEME_COLORS,
          context: {
            headers: {
              accountid: this.$route.params.id
            },
            uri: this.$microservicesUrls['crm']
          },
          variables: {
            mercashopThemeId: item._id
          }
        })
        this.$alert({
          alert_message: `Tema removido com sucesso`,
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
        this.$apollo.queries.mercashopThemes.refresh()
      } catch (error) {
        this.$alert({
          alert_message: `Erro na remoção do tema'}`,
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.colors-group {
  gap: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .color-block {
    width: 24px;
    height: 24px;
    border-radius: 8px;
  }
}
</style>
